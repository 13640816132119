export const Data = {
  profile: {
    image: "./profile.jpg",
    name: "Ali (Allen) Dogramaci",
    occupation: "",
    location: "Sydney, Australia",
    email: "contact@adogramaci.com",
    phone: "",
    profileLabel: "Profile",
    profile: "I've always been passionate about IT, exploring new technologies, optimising systems, and making software.I've always been passionate about IT, exploring new technologies, optimising systems, and making software.",
    skillsLabel: "Skills",
    skills: [
      "Development",
      "Networking",
      "Infrastructure",
      "Support"
    ],
    interestsLabel: "Interests",
    interests: [
      "Self-Hosting",
      "Open Source",
      "Digital Privacy",
    ],
    socialLabel: "Social",
    socials: [
      {
        label: "Github",
        name: "github",
        url: "https://github.com/AliMickey",
        className: "bxl-github",
      },
      {
        label: "LinkedIn",
        name: "linkedin",
        url: "https://linkedin.com/in/ali-dogramaci",
        className: "bxl-linkedin-square",
      }
    ],
  },
  timeline: {
    experiences: [
      {
        title: "Infrastructure Support",
        period: "Mar. 2024 - Present",
        company: "Host Universal",
        country: "AU",
        description: "TBD",
      },
      {
        title: "Desktop Support Analyst",
        period: "Aug. 2022 - Feb. 2024",
        company: "Zurich Insurance",
        country: "AU",
        description: "At Zurich, I had the privilege of contributing to a pivotal major project, aiding in the transition of almost 2,000 users between Active Directory domains in collaboration with global teams. Throughout this experience, I harnessed tools such as Powershell, C#, and Python to streamline processes, including automation of laptop building for new starters and generating access-matrix spreadsheets for auditing. On the BAU front, I extended Level 1, 2, and 3 IT analyst support, encompassing troubleshooting AV systems, managing platforms like Azure AD and Microsoft 365 and learning about network security measures, all of which enriched my technical growth. (Contracted via Interlink Services)",
      },
      {
        title: "IT and Electronics Services Technician",
        period: "May. 2022 - Jul. 2022",
        company: "Interlink Services",
        country: "AU",
        description: "At Interlink, I took on the role of managing assets and troubleshooting hardware issues. I was also able to further my knowledge in Cisco IOS by performing configuration for networks. In addition, I diligently maintained server racks at data centers, ensuring their reliability. My time there provided me with practical knowledge and skills in network management and infrastructure maintenance.",
      },
      {
        title: "Front-of-House Employee",
        period: "Jun. 2015 - Mar. 2020",
        company: "Oporto",
        country: "AU",
        description: "This role taught me important interpersonal skills as I interacted with customers daily. I handled transactions, answered questions and managed orders. The fast-paced setting helped me become efficient and attentive to customers' needs.",
      },
    ],
    educations: [
      {
        title: "Bachelor of Science in Information Technology",
        period: "2019 - 2021",
        institution: "University of Technology Sydney",
        country: "AU",
        description: "WAM: 81.74 ~ GPA: 6.22",
        additionalInfo: [
          "Major in Networking and Cybersecurity",
          "Sub-Major in Data Analytics",
          "Sub-Major in Enterprise Systems Development",
          "------------------------------",
          "Communication for IT Professionals",
          "Web Systems",
          "Introduction to Information Systems",
          "Network Fundamentals",
          "Programming Fundamentals",
          "Applications Programming",
          "Business Requirements Modelling",
          "Fundamentals of Interaction Design",
          "Mobile Networking",
          "Cybersecurity",
          "Data Structures and Algorithms",
          "Database Fundamentals",
          "Routing and Switching Essentials",
          "Application Development with .NET",
          "Cryptography",
          "Introduction to Statistics",
          "Network Servers",
          "Introduction to Data Analytics",
          "IoT Security",
          "Project Management and the Professional",
          "Software Defined Networks",
          "Image Processing and Pattern Recognition",
          "Machine Learning",
          "Internetworking Project",
        ]
      },
      {
        title: "Higher School Certificate",
        period: "2013 - 2018",
        institution: "The Hills Grammar School",
        country: "AU",
        description: "Top mark for Information Processes Technology & General Mathematics",
        additionalInfo: [
          "English Advanced",
          "General Mathematics",
          "Information Technology Processes",
          "Physics",
          "Chemistry",
        ]
      },
    ],
    projects: [
      {
        name: "Starlink Data Tracker",
        company: "Personal",
        period: "Feb. 2022 - Present",
        description: "Community maintained database to track various SpaceX Starlink data (avg. 6000 visitors per month).",
        url: "https://starlinktrack.com"
      },
      {
        name: "End Stat",
        company: "Personal",
        period: "Aug. 2021 - Oct. 2021",
        description: "An open-source platform to facilitate endpoint status monitoring as part of a University project.",
        url: "https://github.com/AliMickey/endstat"
      },
    ],
  },
};
